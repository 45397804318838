import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../service/auth.service';
import { CustomerService } from '../service/customer.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private customerService: CustomerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        
        const currentUser = this.authenticationService.currentUser();
        if (currentUser && currentUser.Token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.Token}`
                }
            });
        }
        if(!request.url.includes('/customer/list')){
            const currentCustomer = this.customerService.currentCustomer();
            if(request.method=='GET' && currentCustomer){
                request = request.clone({
                    setParams: {
                        CustomerId: currentCustomer?.CustomerId as any
                    }
                });
            }
            if(currentCustomer && request.method!=='GET'){
                request = request.clone({
                    body: {
                        ...request.body,
                        CustomerId: currentCustomer?.CustomerId as any
                    }
                });
            }
        }        
        return next.handle(request);
    }
}
