<div class="account-pages my-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="row g-0">
                            <div class="col-lg-6 p-4">
                                <ng-content></ng-content>
                            </div>
                            <div class="col-lg-6 d-none d-md-inline-block" style="background-color: transparent;">
                                <div class="auth-page-sidebar" style="background-color: transparent;">
                                    <!--<div class="overlay"></div>
                                        <div class="auth-user-testimonial">
                                        <p class="fs-24 fw-bold text-white mb-1">Created with Love By</p>
                                        <p>- Sethala</p>
                                    </div>
                                    -->
                                    
                                </div>
                            </div>
                        </div>

                    </div> <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <ng-content select="[footerLink]"></ng-content>
                    </div> <!-- end col -->
                </div>
                <!-- end row -->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->