import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ResultObject } from "../core/models/result-object.model";
import { UserProfileService } from "../core/service/user.service";

@Injectable()
export class LayoutProvider {
  public refreshSubject = new Subject();
  constructor(
    private profileService:UserProfileService
  ) {
  }
  GetUserProfile(){
    return new Promise<ResultObject<any>>((result, exception) => {
      this.profileService.getUserProfile().subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
}