import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherIconDirective } from './feather-icon.directive';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { PageTitleComponent } from './page-title/page-title.component';

@NgModule({
  declarations: [
    FeatherIconDirective,
    DefaultLayoutComponent,
    PreloaderComponent,
    PageTitleComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [FeatherIconDirective, DefaultLayoutComponent, PreloaderComponent,PageTitleComponent]
})
export class UiModule { }
