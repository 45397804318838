<div class="control-section" id="reswrapper">
    <!-- sample level element  -->
    <!-- header-section  declaration -->
    <div>
        <ejs-toolbar colorMode="'Primary'" colorClass="'e-primary'" id="resToolbar"  (clicked)="toolbarCliked($event)">
            <e-items>
                <e-item prefixIcon="bi bi-list tb-icons" tooltipText="Menu"></e-item>
                <e-item>
                    <ng-template #template>
                        <div class="e-folder">
                            <div class="e-folder-name">ID LINK</div>
                        </div>
                    </ng-template>
                </e-item>
                <e-item align="Right">
                    <ng-template #template>
                        <div>
                            <a class="nav-link dropdown-toggle arrow-none" href="javascript:void(0)" (click)="open(drawercontent)" role="button"  style="font-size: 14px; color: #000;" >
                                <i data-feather="codepen" appFeatherIcon></i>
                                {{selectedCustomer?.Label ? selectedCustomer?.Label : 'Select Customer'}}
                            </a>
                        </div>
                    </ng-template>
                </e-item>
                
                <e-item cssClass="e-custom" align="Right">
                    <ng-template #template>
                        <div >
                            <a class="nav-link dropdown-toggle nav-user me-0" href="javascript:void(0)" role="button"  style="font-size: 14px; color: #000;">
                                <!-- <img src="assets/images/users/avatar-1.jpg" alt="user-image" class="rounded-circle"> -->
                                <img [src]="loggedInUser.CompanyLogo? loggedInUser.CompanyLogo:'https://files.sethala.com/sethala/6256ca9d5967d20220413-150533.png'" alt="user-image" class="rounded-circle">
                                <span class="pro-user-name ms-1">
                                    {{loggedInUser.Fullname}} 
                                    <!-- SethalaDev  -->
                                </span>
                            </a>
                     
                        </div>
                    </ng-template>
                </e-item>


                <e-item align="Right">
                    <ng-template #template>
                      <div>
                        <button class="nav-link btn btn-link" (click)="redirectToAccount()" style="font-size: 14px; color: #000;">
                            <i class="bi bi-person-fill" style="font-size: 24px;"></i> Account
                        </button>
                      </div>
                    </ng-template>
                  </e-item>

                <e-item cssClass="e-custom" align="Right">
                    <ng-template #template>
                      <div>
                        <a class="nav-link dropdown-toggle nav-user me-0" (click)="logoutUser()" href="javascript:void(0)" role="button"
                          style="font-size: 16px; color: red;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                            <path d="M7.5 1v7h1V1h-1z"></path>
                            <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"></path>
                          </svg>
                        </a>
                      </div>
                    </ng-template>
                  </e-item>
                  

            </e-items>
        </ejs-toolbar>
    </div>
    <!-- end of header-section -->


    <ejs-sidebar id="sideTree" class="sidebar-treeview" #sidebarTreeviewInstance [width]="width" [target]="target" 
        [mediaQuery]="mediaQuery" [isOpen]="true" >
        <div class='main-menu'>
            <div class="d-flex justify-content-center" style="margin-top: 20px;">
                <img src="assets/images/logo-dark.png" alt="" height="32">
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 10px;">
                <span class="text-muted"><small> Version {{versionNumber}}</small></span>
            </div>
            <div class="table-content">
                <ejs-textbox id="resSearch" placeholder="Search..." (valueChange)="searchMenu($event)"></ejs-textbox>
                <p class="main-menu-header">Main Menu</p>
            </div>
            <div>
                <ejs-treeview id='mainTree' cssClass="main-treeview" [fields]="fields" expandOn='Click' (nodeClicked)="nodesChecked($event)">
                </ejs-treeview>
            </div>
        </div>
    </ejs-sidebar>

    <!-- end of sidebar element -->
    <!-- .main-sidebar-content declaration -->
    <div class="main-sidebar-content" id="main-text">
        <div class="sidebar-content" style="background-color: #E4E7EA;height: 100vh;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<ng-template #drawercontent let-offcanvas>
    <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvas-basic-title">Select Customer</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <form>
            <div class="mb-3">
                <div class="input-group">
                    <input (change)="_searchCustomers($event)" (cancel)="_searchCustomers($event)" type="search" class="form-control" placeholder="Search..." id="top-search" >
                    <button class="btn btn-light bi bi-search" type="button"></button>
                </div>
            </div>
        </form>
        <div  class="d-flex border-bottom p-1" style="justify-content:flex-start; cursor: pointer;"  *ngFor="let customer of filteredCustomers" (click)="selectCustomer(customer);offcanvas.dismiss('Cross click')">
            <img [src]="customer.CompanyLogo? customer.CompanyLogo:'https://files.sethala.com/sethala/6256ca9d5967d20220413-150533.png'" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{customer.Label}}</span>
        </div>
    </div>
</ng-template>