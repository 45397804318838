import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui/ui.module';
import { FooterComponent } from './footer/footer.component';
import { HorizontalLayoutComponent } from './layout.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TopnavComponent } from './topnav/topnav.component';
import { NgbCollapseModule, NgbAlertModule, NgbDropdownModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SyncfusionModule } from '../shared/syncfusion.module';
import { LayoutProvider } from './layout.provider';
import { AuthService } from '../auth/service/auth.service';



@NgModule({
  declarations: [
    LeftSidebarComponent,
    RightSidebarComponent,
    TopbarComponent,
    FooterComponent,
    HorizontalLayoutComponent,
    TopnavComponent
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    RouterModule,
    FormsModule,
    ClickOutsideModule,
    SimplebarAngularModule,
    NgbAlertModule,
    NgbDropdownModule,
    UiModule,
    NgbOffcanvasModule,
    SyncfusionModule

  ],
  providers : [LayoutProvider,AuthService],
  exports: [
    HorizontalLayoutComponent,
    TopnavComponent
  ]
})
export class LayoutModule { }
