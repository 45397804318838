import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

  constructor(protected override router: Router, protected override keycloakAngular: KeycloakService) {
    super(router, keycloakAngular);
  }


  public async isAccessAllowed(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];
    var currentUser = {
      User : await this.keycloakAngular.loadUserProfile(),
      Token : (await this.keycloakAngular.getToken()).toString()
    }
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    // console.log(requiredRoles);
    // console.log(this.roles);
    // Allow the user to proceed if all the required roles are present.
    // if( requiredRoles.every((role) => this.roles.includes(role)) ){
    if( requiredRoles.every((role) => this.roles.includes(role)) ){
      return true;
    }
    else{
      return this.router.parseUrl('/app/miscellaneous/forbidden');
    }
  }

}
/*console.log(requiredRoles);
    console.log(this.roles);
    var rs = requiredRoles.every((role) => this.roles.includes(role))
    console.log(rs)
    // Allow the user to proceed if all the required roles are present.
    // if( requiredRoles.every((role) => this.roles.includes(role)) ){
    var atLeast1 = false;
    for( var role of requiredRoles){
      if(this.roles.includes(role)){
        atLeast1 = true;
        break;
      }
    }
    if( atLeast1 ){
      return true;
    }
    else{
      return this.router.parseUrl('/app/miscellaneous/forbidden');
    }
  */