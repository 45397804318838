import { Component, OnInit } from '@angular/core';
import { LayoutEventType } from './core/constants/events';
import { SIDE_DEFAULT_VIEW } from './core/constants/variables';
import { EventService } from './core/service/event.service';
import { LAYOUT_DETACHED, LAYOUT_VERTICAL } from './layout/config/layout.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  leftSidebarType: string | undefined;
  layoutType: string | undefined;
  constructor(
    private eventService: EventService
  ){
    
  }
  ngOnInit(): void {
    var currentSetting = localStorage.getItem(SIDE_DEFAULT_VIEW);
    if(currentSetting){
      this.changeLeftSidebarType(currentSetting);
    }
  }
  title = 'informed-decision-iot';
  changeLeftSidebarType(type: string): void {
    this.leftSidebarType = type;
    if (this.layoutType === LAYOUT_VERTICAL || this.layoutType === LAYOUT_DETACHED) {
      this.eventService.broadcast(LayoutEventType.CHANGE_LEFT_SIDEBAR_TYPE, type);
    }
  }
}
