import { MenuItem } from "src/app/core/models/menu.model";


// menu items for vertcal and detached layout
const MENU_ITEMS: MenuItem[] = [
    {
        key: 'dashboard',
        label: 'Dashboards',
        isTitle: false,
        icon: 'home',
        collapsed: false,
        link: '/app/secure/dashboard'
    },
    {
        key: 'irrigation',
        label: 'Irrigation',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/irrigation',
    },
    {
        key: 'fuel-monitoring',
        label: 'Fuel Monitoring',
        isTitle: false,
        icon: 'hard-drive',
        collapsed: false,
        link: '/app/secure/fuel-monitoring',
    },
    // {
    //     key: 'map-view',
    //     label: 'Map',
    //     isTitle: false,
    //     icon: 'map',
    //     collapsed: false,
    //     link: '/app/secure/maps',
    // },
    {
        key: 'water-monitoring',
        label: 'Water Monitoring',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/water-monitoring'
    },
    {
      key: 'pumb-control',
      label: 'Pumb Control',
      isTitle: false,
      icon: 'dpad',
      collapsed: false,
      link: 'app/secure/pumb-control'
    }
];

// menu items for two column menu layout
const TWO_COl_MENU_ITEMS: MenuItem[] = [
    {
        key: 'dashboard',
        label: 'Dashboards',
        isTitle: false,
        icon: 'home',
        collapsed: false,
        link: '/app/secure/dashboard'
    },
    {
        key: 'irrigation',
        label: 'Irrigation',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/irrigation/',
    },
    {
        key: 'fuel-monitoring',
        label: 'Fuel Monitoring',
        isTitle: false,
        icon: 'hard-drive',
        collapsed: false,
        link: '/app/secure/fuel-monitoring',
    },
    // {
    //     key: 'map-view',
    //     label: 'Map',
    //     isTitle: false,
    //     icon: 'map',
    //     collapsed: false,
    //     link: '/app/secure/maps',
    // },
    {
        key: 'water-monitoring',
        label: 'Water Monitoring',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/water-monitoring',
    },
    {
      key: 'pumb-control',
      label: 'Pumb Control',
      isTitle: false,
      icon: 'dpad',
      collapsed: false,
      link: 'app/secure/pumb-control'
    }
];

// menu items for horizontal layout
const HORIZONTAL_MENU_ITEMS: MenuItem[] = [
    {
        key: 'dashboard',
        label: 'Dashboards',
        isTitle: false,
        icon: 'home',
        collapsed: false,
        link: '/app/secure/dashboard'
    },
    {
        key: 'irrigation',
        label: 'Irrigation',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/irrigation',
    },
    {
        key: 'fuel-monitoring',
        label: 'Fuel Monitoring',
        isTitle: false,
        icon: 'hard-drive',
        collapsed: false,
        link: '/app/secure/fuel-monitoring',
    },
    // {
    //     key: 'map-view',
    //     label: 'Map',
    //     isTitle: false,
    //     icon: 'map',
    //     collapsed: false,
    //     link: '/app/secure/maps',
    // },
    {
        key: 'water-monitoring',
        label: 'Water Monitoring',
        isTitle: false,
        icon: 'droplet',
        collapsed: false,
        link: '/app/secure/water-monitoring',
    },
    {
      key: 'pumb-control',
      label: 'Pumb Control',
      isTitle: false,
      icon: 'dpad',
      collapsed: false,
      link: 'app/secure/pumb-control'
    }
]

export { MENU_ITEMS, TWO_COl_MENU_ITEMS, HORIZONTAL_MENU_ITEMS }
