import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { LayoutEventType } from 'src/app/core/constants/events';
import { EventService } from 'src/app/core/service/event.service';
import { environment } from 'src/environments/environment';
import { LAYOUT_TWO_COLUMN_MENU } from './config/layout.model';
import { changeBodyAttribute, getLayoutConfig } from './helper/utils';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { CustomerService } from 'src/app/core/service/customer.service';
import { Select2Data } from 'ng-select2-component';
import { BrandItem } from 'src/app/core/models/brands.model';
import { CreateNewMenuOption } from 'src/app/core/models/create-new.model';
import { CustomerModel } from 'src/app/core/models/customer.model';
import { NotificationItem } from 'src/app/core/models/notification.model';
import { ProfileOptionItem } from 'src/app/core/models/profileoption.model';
import { SearchResultItem, SearchUserItem } from 'src/app/core/models/search.model';
import { Language } from 'src/app/core/models/language.model';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { LayoutProvider } from './layout.provider';
import { AuthService } from '../auth/service/auth.service';
import Swal from 'sweetalert2';
import * as _ from "lodash"
@Component({
  selector: 'app-horizontal-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class HorizontalLayoutComponent implements OnInit {
  @ViewChild('sidebarTreeviewInstance') public sidebarTreeviewInstance!: SidebarComponent;
  public versionNumber : string =  environment.versionNumber;
  public menuItems :any[] = []
  public verticalMenuItems: any[] = [
    {
      iconCss: 'e-icons e-more-vertical-1',
      items:this.menuItems.map(x=>{ return  { text: x.nodeText } })
    }
  ]
  public data: { [key: string]: Object }[] = []
  public appBarColor = { colorMode: 'Dark', colorClass: 'e-dark', isPrimary: 'false', loginClass: 'e-inherit login' }
  public width: string = '200px';
  public target: string = '.main-sidebar-content';
  public mediaQuery: string = '(min-width: 600px)';
  public fields: object = { dataSource: this.data, id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: "iconCss" };

  languages: Language[] = [];
  brands: BrandItem[] = [];
  profileOptions: ProfileOptionItem[] = [];
  selectedLanguage?: Language;
  searchResults: SearchResultItem[] = [];
  searchUsers: SearchUserItem[] = [];
  customersSelect:Select2Data=[]
  customers:CustomerModel[]=[];
  filteredCustomers:CustomerModel[]=[];
  loggedInUser: any = {};
  error: any;
  selectedCustomer: CustomerModel | null= null;
  searchCustomer: any;
  closeResult = '';
  solutions: any;
  constructor(
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private authService: AuthenticationService,
    private eventService: EventService,
    private customerService:CustomerService,
    private layoutProvider :LayoutProvider,
    private keyCloakService : AuthService
  ) {

  }
  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    this.fields = { dataSource: this.menuItems, id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: "iconCss" }
    this._fetchCustomers();
    this.loggedInUser = this.authService.currentUser();
    this.selectedCustomer = this.customerService.currentCustomer();
    this.getUser()
  }
  // toolbarCliked(): void {
  //     this.sidebarTreeviewInstance.toggle();
  // }

// Add the redirectToAccount() method here
redirectToAccount(): void {
  window.open('https://khiya.sethala.app/realms/sethala/account/applications', '_blank');
}

toolbarCliked(args:any) {
 // console.log(args);
  if(args.item.tooltipText == "Menu") {
      this.sidebarTreeviewInstance.toggle();
  }
}
logoutUser(){
  Swal.fire({
    icon:"question",
    title:"Please Confirm?",
    text: 'Do you want to logout from the application?',
    showCancelButton: true,
    confirmButtonColor:"danger",
    confirmButtonText: '<i class="bi bi-save"></i> Logout',
    cancelButtonText: '<i class="bi bi-x-lg"></i> Cancel',

  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      this.keyCloakService.logout();
    } else if (result.isDenied) {
      //this.toastService.info('Changes are not saved','No Changes!');
    }
  })
}
getUser(){
  this.layoutProvider.GetUserProfile().then(result=>{
    if(result.Success){
      this.loggedInUser = result.Data?.Profile;
      this.solutions = result.Data?.Solutions;
      var solutionIds :any[] = result.Data?.Solutions?.map((x: { id: any; })=>x.id);
      //var selectedSolutions = applicationSolutions.filter(x=>solutionIds.includes( x.sqlId) );
      var selectedSolutions;
      if(this.loggedInUser.RoleId == 5)
      {
        selectedSolutions = applicationSolutions;
      }else
      {
         selectedSolutions = applicationSolutions.filter(x=>solutionIds.includes( x.sqlId) );
      }

      this.menuItems = [
        ...basicMenuItems,
        ...selectedSolutions,
        ...notificationsMenuItems
      ]
      this.fields = { dataSource: this.menuItems, id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: "iconCss" }
    }
  })
}
  public searchMenu(e:any){
    var filtered = this.menuItems.filter(x=>x.nodeText.toLowerCase().includes(e))
    this.fields = { dataSource: filtered, id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: "iconCss" }
  }
  public nodesChecked(e:any){
    if(e?.node?.dataset?.uid){
      var node = this.menuItems.find(x=>x?.nodeId==e?.node?.dataset?.uid);
      if(node?.link){
        this.router.navigateByUrl(node.link)
      }
    }
  }
  public onCreate(): void {
    const menuButtonElement = document.querySelectorAll('.color-appbar-section .e-inherit.menu');
    for (let i = 0; i < menuButtonElement.length; i++) {
      if(!(menuButtonElement[i].hasAttribute("aria-label"))) {
        menuButtonElement[i].setAttribute('aria-label','menu');
      }
    }
  }
  public focusIn(target: any): void {
    target.parentElement.classList.add('e-input-focus');
  }
  public focusOut(target: any): void {
    target.parentElement.classList.remove('e-input-focus');
  }
  public onBeforeItemRender(args: any): void {
    if (args.element.children.length > 0 && args.element.children[0].classList.contains("e-more-vertical-1")) {
      args.element.setAttribute('aria-label', 'more vertical');
    }
  }
  _fetchCustomers(){
    this.customerService.getAllCustomers({})
    .pipe(first())
    .subscribe(
      (data) => {
        if(data.Success){
          this.customers =  data.Data as CustomerModel[];
          this.filteredCustomers = data.Data as CustomerModel[];
          if(this.searchCustomer && data.Data ){
            const search =this.searchCustomer.toLowerCase();
            this.filteredCustomers = _.filter(data.Data, function(customer ) { return customer?.Label?.toLowerCase().includes( search )}) as CustomerModel[];
          }
          // this.customersSelect  =[ {
          //   label: 'Select Customer',
          //   options:  _.map(this.customers,x=>{return {value:x.CustomerGuid,label:x.Label}})
          //   }] as Select2Data
        }

      },
      (error: any) => {
        this.error = error;
      });
  }
  selectCustomer(customer:CustomerModel){
    this.selectedCustomer = customer;
    this.customerService.selectCustomer(customer);
  }
  _searchCustomers(e:any){
    this.searchCustomer=e?.target?.value;
    this.filteredCustomers = [ ...this.customers ];
    if(this.searchCustomer && this.customers ){
      const search =this.searchCustomer.toLowerCase();
      this.filteredCustomers= _.filter(this.customers, function(customer ) { return customer?.Label?.toLowerCase().includes( search )}) as CustomerModel[];
    }
  }
  open(content:any) {
    this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  redirectTo()
  {
     this.router.navigate(['/app/secure/user/profile/'+this.authService.currentUser()?.Uuid]);
  }

}
const basicMenuItems = [
  {
    key: 'dashboard',
    nodeId: 'main-dashboard', nodeText: 'Dashboard', iconCss: 'bi bi-speedometer icon',
    link: 'app/secure/dashboard'
  },
  {
    key: 'map',
    nodeId: 'map-view', nodeText: 'Map', iconCss: 'bi bi-map icon',
    link: 'app/secure/maps',
  }
]
const notificationsMenuItems = [
  {
    key: 'notifications',
    nodeId: 'notifications-dashboard', nodeText: 'Notification Rules', iconCss: 'bi bi-bell icon',
    link: 'app/secure/notifications'
  }
]
const applicationSolutions = [
  {
    key: 'irrigation',
    nodeId: 'irrigation', nodeText: 'Irrigation Monitoring', iconCss: 'bi bi-moisture icon',
    link: 'app/secure/irrigation',
    sqlId : 28
  },
  {
      key: 'fuel-monitoring',
      nodeId: 'fuelmonitoring', nodeText: 'Fuel Monitoring', iconCss: 'bi bi-fuel-pump icon',
      link: 'app/secure/fuel-monitoring',
      sqlId : 13
  },
  {
    key: 'electricity',
    nodeId: 'electricity', nodeText: 'Electricity', iconCss: 'bi bi-battery-charging icon',
    link: 'app/secure/electricity',
    sqlId : 38
  },

  {
    key: 'water-monitoring',
    nodeId: 'water-monitoring', nodeText: 'Water Monitoring', iconCss: 'bi bi-water icon',
    link: 'app/secure/water-monitoring',
    sqlId : 14
  },

  {
    key: 'water-balance',
    nodeId: 'water-balance', nodeText: 'Water Balance', iconCss: 'bi bi-droplet-half icon',
    link: 'app/secure/water-level',
    sqlId : 36
  },

  {
    key: 'heat-recovery',
    nodeId: 'heat-recovery', nodeText: 'Heat Recovery', iconCss: 'bi bi-thermometer icon',
    link: 'app/secure/heat-recovery',
    sqlId : 37
  },

  {
    key: 'air-quality',
    nodeId: 'air-quality', nodeText: 'Air Quality', iconCss: 'bi bi-fan icon',
    link: 'app/secure/air-quality',
    sqlId : 39
  },
  {
    key: 'Relay-control',
    nodeId: 'Relay-control', nodeText: 'Relay Control', iconCss: 'bi bi-dpad icon',
    link: 'app/secure/pumb-control',
    sqlId : 40
  }
]
