import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerModel } from '../models/customer.model';
import { ResultArray } from '../models/result-array.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  coreBaseUrl = environment.coreUrl;
  dataObject:object={}
  customer: CustomerModel | null = null;
  public customerSubject = new Subject();
  constructor (private http: HttpClient) {
  }
  selectCustomer(customer:CustomerModel){
    sessionStorage.setItem('currentCustomer',JSON.stringify(customer));
    this.customer = customer
    this.customerSubject.next(customer)
  }
  ping(){
    return this.http.get(this.coreBaseUrl+'/customer/ping')
  }
  getAllCustomers(query?:CustomerModel){
    return this.http.get<ResultArray<CustomerModel>>(this.coreBaseUrl+'/customer/list',{params:query as any});
  }
  public currentCustomer(): CustomerModel | null {
    if (!this.customer) {
        this.customer = getSelectedCustomer();
    }
    return this.customer;
}
}
function getSelectedCustomer(): CustomerModel | null {
  let customer: CustomerModel | null = {};
  customer = JSON.parse(sessionStorage.getItem('currentCustomer')!);
  return customer;
}
