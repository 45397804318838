<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-end mb-0">

 
            <li class="d-none d-lg-inline-block topbar-dropdown" >
                <a class="nav-link dropdown-toggle arrow-none" href="javascript:void(0)" (click)="open(drawercontent)" role="button" >
                    <i data-feather="codepen" appFeatherIcon></i>
                    {{selectedCustomer?.Label ? selectedCustomer?.Label : 'Select Customer'}} 
                </a>
            </li>
            <li class="dropdown d-none d-lg-inline-block">
                <a class="nav-link dropdown-toggle arrow-none maximize-icon" href="javascript:void(0)"
                    (click)="toggleFullScreen()">
                    <i data-feather="maximize" appFeatherIcon></i> Fullscreen
                </a>
            </li>

            <li class="d-none d-lg-inline-block topbar-dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle arrow-none" href="javascript:void(0)" role="button"
                    ngbDropdownToggle>
                    <i data-feather="globe" appFeatherIcon></i> English
                </a>
                <div ngbDropdownMenu class="dropdown-menu-end">

                    <!-- item-->
                    <a href="javascript:void(0);" ngbDropdownItem *ngFor="let language of languages" >
                        <img [src]="language.flag" alt="user-image" class="me-1" height="12"> <span
                            class="align-middle">{{language.name}}</span>
                    </a>

                </div>
            </li>

            <li class="notification-list topbar-dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle nav-user me-0" href="javascript:void(0)" role="button"
                    ngbDropdownToggle>
                    <!-- <img src="assets/images/users/avatar-1.jpg" alt="user-image" class="rounded-circle"> -->
                    <img [src]="loggedInUser.CompanyLogo? loggedInUser.CompanyLogo:'https://files.sethala.com/sethala/6256ca9d5967d20220413-150533.png'" alt="user-image" class="rounded-circle">
                    <span class="pro-user-name ms-1">
                        {{loggedInUser.Fullname}} 
                        <!-- SethalaDev  -->
                    </span>
                </a>
                <div ngbDropdownMenu aria-labelledby="profileMenuDropdown" class="dropdown-menu-end profile-dropdown">
                    <!-- item -->
                    <div ngbDropdownItem class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome {{loggedInUser.Name}}!</h6>
                    </div>
                    <!-- item -->
                    <ng-container *ngFor="let option of profileOptions;let i=index">
                        <div class="dropdown-divider" *ngIf="i===profileOptions.length-1"></div>
                        <a class="notify-item" ngbDropdownItem>
                            <i [attr.data-feather]="option.icon" appFeatherIcon class="icon-dual icon-xs me-1"></i>
                            <span style="cursor: pointer;" [routerLink]="[option.redirectTo]">{{option.label}}</span>
                        </a>
                    </ng-container>

                </div>
            </li>

            <li class="dropdown notification-list">
                <a href="javascript:void(0);" class="nav-link right-bar-toggle" (click)="toggleRightSidebar()">
                    <i data-feather="settings" appFeatherIcon></i>
                </a>
            </li>

        </ul>

        <!-- LOGO -->
        <div class="logo-box">
            <a [routerLink]="'../dashboard/maindashboard'" class="logo logo-dark">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="24">
                    <!-- <span class="logo-lg-text-light">ID IoT </span> -->
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="24">
                    <span class="text-muted"><small> V{{versionNumber}}</small></span>
                </span>
            </a>

            <a [routerLink]="'../dashboard/maindashboard'" class="logo logo-light">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="24">
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-light.png" alt="" height="24">
                </span>                
            </a>
            
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <!-- <li>
                <button class="button-menu-mobile" (click)="changeSidebarWidth()">
                    <i data-feather="menu" appFeatherIcon></i>
                </button>
            </li> -->
            <li>
                <button class="button-menu-mobile  d-none d-lg-block" (click)="changeSidebarWidth()"
                    *ngIf="layoutType!=='horizontal'">
                    <i data-feather="menu" appFeatherIcon></i>
                </button>
            </li>

            <li>
                <button class="button-menu-mobile open-left d-block d-lg-none " *ngIf="layoutType!=='horizontal'"
                    (click)="toggleMobileMenu($event)">
                    <i data-feather="menu" appFeatherIcon></i>
                </button>
            </li>

            <li>
                <!-- Mobile menu toggle (Horizontal Layout)-->
                <a class="navbar-toggle nav-link" [ngClass]="{'open': topnavCollapsed}"
                    (click)="toggleMobileMenu($event)" *ngIf="layoutType==='horizontal'">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
</div>
<!-- end Topbar -->
<ng-template #drawercontent let-offcanvas>
    <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvas-basic-title">Select Customer</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <form>
            <div class="mb-3">
                <div class="input-group">
                    <input (change)="_searchCustomers($event)" type="search" class="form-control" placeholder="Search..." id="top-search" >
                    <button class="btn btn-light bi bi-search" type="button"></button>
                </div>
            </div>
        </form>
        <div  class="d-flex border-bottom p-1" style="justify-content:flex-start; cursor: pointer;"  *ngFor="let customer of customers" (click)="selectCustomer(customer);offcanvas.dismiss('Cross click')">
            <img [src]="customer.CompanyLogo? customer.CompanyLogo:'https://files.sethala.com/sethala/6256ca9d5967d20220413-150533.png'" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{customer.Label}}</span>
        </div>
    </div>
</ng-template>