import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { loggedInUser } from '../helpers/utils';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User | null = null;
    coreBaseUrl = environment.coreUrl
    constructor (private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User | null {
        if (!this.user) {
            this.user = loggedInUser();
        }
        return this.user;
    }

    /**
     * Performs the login auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string): any {
        return this.http.post<any>(`${this.coreBaseUrl}/adm-user/openid/login-user`, { EmailAddress:email, Password:password })
            .pipe(map(result => {
                console.log(result)
                // login successful if there's a jwt token in the response
                if (result && result.Success) {
                    this.user = result.Data;
                    // store user details and jwt in session
                    sessionStorage.setItem('currentUser', JSON.stringify(this.user));
                }
                else{
                    throw result.Message
                }
                return this.user;
            }));
    }

    /**
     * Performs the signup auth
     * @param name name of user
     * @param email email of user
     * @param password password of user
     */
    signup(name: string, email: string, password: string): any {
        return this.http.post<any>(`${this.coreBaseUrl}/api/signup`, { name, email, password })
            .pipe(map(user => user));

    }

    resetPassword(): any {
        return this.http.get<any>(`${this.coreBaseUrl}/adm-user/openid/resetPassword`)
            .pipe(map(result => {
                console.log(result)
                // login successful if there's a jwt token in the response
                if (result && result.Success) {
                    return result.Data;
                }
                else{
                    throw result.Message
                }
            }));
    }

    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        sessionStorage.removeItem('currentUser');
        this.user = null;
    }
}

