import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    coreBaseUrl = environment.coreUrl;
    constructor(private http: HttpClient) { }
    getUserProfile(){
        return this.http.get(this.coreBaseUrl+'/adm-user/getUserProfile')
      }
    getAll() {
        return this.http.get<User[]>(`/api/login`);
    }
}