import * as moment from "moment";

export const ARIAL_LEVEL = "ariaLevel";
export const SELECTED_ENTITY = "nodeId";
export const THING_TYPES = "thing-types-list";
export const THING_STATUS = "thing-status-list";
export const THING_DIMENSION = "thing-dimension-list"
export const SIDE_DEFAULT_VIEW = "left-side-bar-toogle"
export const REPORT_FILTER = "report-filter"
export const REPORT_WATER_RATE = "REPORT_WATER_RATE"
export const CONVERT_TO_LOCAL_DATE = (timestamp : Date) : Date => {
    const tz = moment().utcOffset();
    return moment(timestamp).add(tz,"minutes").toDate()
}