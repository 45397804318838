import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path:'app',
    canActivate: [AuthGuard],   
    loadChildren:()=> import('./modules/modules.module').then(m => m.ModulesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
