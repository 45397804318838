import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

 import * as SyncfusionNavigations  from '@syncfusion/ej2-angular-navigations';
 import * as SyncfusionDropdowns from '@syncfusion/ej2-angular-dropdowns';
 import * as SyncfusionLayouts from '@syncfusion/ej2-layouts';
 import * as SyncfusionInputs from '@syncfusion/ej2-angular-inputs';
 import { ChartAllModule, AccumulationChartAllModule } from '@syncfusion/ej2-angular-charts';
 import * as SynfusionGrids from '@syncfusion/ej2-angular-grids';
 import * as SyncFusionCalender from '@syncfusion/ej2-angular-calendars'
 import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
 import * as  SkeletonModule  from '@syncfusion/ej2-angular-notifications';
 import * as SyncfusionButtons  from '@syncfusion/ej2-angular-buttons';
 import * as DialogModule from '@syncfusion/ej2-angular-popups';
 import { RadioButtonModule ,ButtonModule} from '@syncfusion/ej2-angular-buttons';
 import * as  ListViewModule from '@syncfusion/ej2-angular-lists';
 import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

import { DropDownListModule , ComboBoxAllModule} from '@syncfusion/ej2-angular-dropdowns';

import { SidebarModule, MenuAllModule, TreeViewAllModule, AppBarAllModule} from '@syncfusion/ej2-angular-navigations';

const SyncfusionUI = [
  SyncfusionNavigations.AccordionAllModule,
  SyncfusionNavigations.BreadcrumbAllModule,
  SyncfusionNavigations.SidebarAllModule,
  SynfusionGrids.GridAllModule,
  SyncfusionDropdowns.DropDownListAllModule,
  SyncfusionNavigations.TabAllModule,
  SyncfusionNavigations.ToolbarAllModule,
  SyncfusionNavigations.AppBarAllModule,
  SyncFusionCalender.CalendarAllModule,
  SyncFusionCalender.DatePickerAllModule,
  SyncFusionCalender.TimePickerAllModule,
  SkeletonModule.SkeletonAllModule,
  SkeletonModule.ToastAllModule,
  AccumulationChartAllModule,
  ChartAllModule,
  DateRangePickerModule,AppBarAllModule,
  SyncfusionButtons.ButtonAllModule,SyncfusionButtons.SwitchAllModule,
  SyncfusionButtons.CheckBoxAllModule,
  DialogModule.DialogAllModule,
  DialogModule.TooltipAllModule,SyncfusionInputs.MaskedTextBoxAllModule,
  RadioButtonModule,SyncfusionInputs.NumericTextBoxAllModule,
  ComboBoxAllModule,
  ListViewModule.ListViewAllModule,
  SidebarModule,  TextBoxAllModule,  MenuAllModule, DropDownListModule, ButtonModule, TreeViewAllModule
];

@NgModule({
  imports: [
        CommonModule,
        ...SyncfusionUI
    ],
  declarations: [],
  exports: [
    ...SyncfusionUI
  ]
})
export class SyncfusionModule {}
