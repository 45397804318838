// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ****** USED IN DEV SERVER ONLY !!! ******
// ****** DO NOT CHANGE THIS FILE !!!! ******

export const environment = {
  production: false,
  Name: 'development',
  Version: '1.0.10',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
  versionNumber: "1.4.3",
  // qa
  //coreUrl:'https://sethala-iot-api-qa.sethala.com/v2',
  coreUrl : 'https://api-nkosi-prod-nsa.sethala.com/v2',
  // prod
  //coreUrl:'https://api-nkosi-prod-nsa.sethala.com/v2',
  applicationUrl:'https://iot-dev-api.sethala.com',
  noImageUrl:'https://files.sethala.com/sethala/6256ca9d5967d20220413-150533.png',
  keycloak: {
    url: 'https://khiya.sethala.app',
    realm: "sethala-dev",
    clientId: "idlink-dev",
  },
  idLinkMandetoryRole: ["default-roles-sethala-dev"],
  mqtt: {
    server: 'datagate.sethala.com',
    username: 'idlink',
    password:'#^2ck2ML!Q#2qA6#4Qx237gf',
    protocol: "wss",
    port: 8083
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
